import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const sentiercheminavantpropos = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Avant-propos" />
    <h2 className='underline-title'>Avant-propos</h2>
    <br /><br />
    <div className="table-responsive">
      <div className="properly-aligned text-white">
        <span className="par-starting"></span>Certains de mes amis sont curieux et me posent les questions
        suivantes:
        <ul className="bout">
          <li>Pourquoi écris-tu des poèmes?</li>
          <li>Qu'entends-tu par poésie?</li>
        </ul> 
      </div>
      <div className="properly-aligned text-white">
        <span className="par-starting"></span>J'écris des poèmes:<br />
        <ul className="bout">
          <li>pour me libérer de certaines idées qui me tiennent leur prisonnier.
        Ces idées s'accollent à moi à tel point que je ne peux m'en débarasser que lorsqu'elles sont couchées sur papier;
          </li>
          <li>pour donner matières à devinette à certains esprits, notamment
        aux étudiants et chercheurs futurs. Je pense qu'avec le temps, mon modeste travail de libération personnelle pourra 
        devenir pour étudiants et pour ceux qui s'intéressent aux recherches des travaux intellectuels, matières à devinette. 
        Je me plais à répéter qu'il s'agit bien de matières à devinette, parce que je crois vraiment pqu que l'on 
        interprète avec exactitude des poèmes écrits par un autre. Ici, j'avoue qu'il m'arrive parfois (pour certains de 
        mes propres poèmes!) de ne pas me retrouver avec facilité dans divers fonds qui donnèrent naissance à quelques uns 
        de mes poèmes. 
          </li>
          <li>pour divertir un nombre d'âmes blasées de rengaines autres que choses 
        de l'esprit; et enfin
         </li>
          <li>pour transmettre ce que je pense être un certain message transmissible</li>
        </ul>
      </div>
      <p className="properly-aligned">
        <span className="par-starting"></span>En ce qui concerne la seconde question, je précise à l'intention de mes 
        amis que je ne suis pas du tout un doctrinaire. Qui de nous n'esquisse pas un sourire moqueur lorsqu'il apprend 
        qu'on parle de doctrine en poésie contemporaine? <a href="https://en.wikipedia.org/wiki/Pierre_Seghers" target="_blank" rel="noopener noreferrer">Pierre Seghers</a> confirme<span className="font-italic"> "qu'il n'y pas d'écoles doctrinaires en poésie des 
        contemporains (seulement des suiveurs). Chaque artiste se crée sa propre discipline. On naît poète comme on naît 
        blanc ou noir. La poésie est le pivot de celui qui se cherche dans ses contradictions, dans le déséquilibre de ses 
        forces, la voix d'un appel insensé, présence, enfin présence en dépit des phantasmes..."</span>
      </p>
      <p className="properly-aligned">  
        <span className="par-starting"></span>Pour moi, en plus de ce que vient de dire Mr. Seghers, j'entends par poésie, un 
        langage quelque peu algébrique; un langage où toutes les notes s'accordent d'une manière ou d'une autre. En 
        d'autres mots, c'est un domaine où les négatifs tout comme les positifs ne se repoussent pas. Par exemple, un 
        poète avait juxtaposé les mots que voici: Je suis égaré en pleine ville-lumière des totales ténèbres. Ce sont-là 
        des mots impossibles dans une phrase normale. Comment peut-on concevoir une lumière en totales ténèbres? 
        Et pourtant...le poète, par ce genre de paradoxe, avait dit ce qu'il voulait dire...et avec force! C'est finalement, 
        à mon avis, comme si ce poète avait écrit: (-1) - (-3). Cette opération algébrique paraît abracadabrante (pour un novice) 
        lorsqu'on la considère sous l'angle arithmétique où habituellement UN moins TROIS (1 - 3) n'est pas possible(!), 
        alors qu'en algèbre il y a un résultat accepté! C'est dans ce sens-là que je conçois la poésie actuelle. Elle est 
        pour moi un champ où pousse un amalgame de plantes (vivrières et non vivrières) sans toutefois se gêner.
      </p>
      <p className="properly-aligned">
        <span className="par-starting"></span>Avec ceci, je pense fournir une réponse, vague soit-elle, à mes curieux
        amis et espère qu'ils cesseront désormais de me harceler de questions sur ce bicéphale d'animal qu'est la poésie.
      </p>
      <br />
      <p className="date-published">Bukavu, le 9 novembre 1977</p>
      <p>L'auteur</p>
    </div> 
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default sentiercheminavantpropos
